import NextErrorComponent from 'next/error';
import { NextPage } from 'next';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

const availableLanguages = ['de', 'en', 'ru', 'tr', 'fr', 'bg', 'ro', 'ar', 'uk'];

const NotFound: NextPage = () => {
  const router = useRouter();
  const hasTried = availableLanguages.some((lang) => router.asPath.startsWith(`/${lang}`));

  useEffect(() => {
    if (!hasTried) {
      router.push(`de/${router.asPath}`);
    }
  }, []);

  return !hasTried ? <></> : <NextErrorComponent statusCode={404} />;
};
export default NotFound;
